import cloneDeep from "lodash.clonedeep";
import { useEffect } from "react";
import Icon from "../../../common/table/customCells/checkmark";
import PersonModalCell from "./personModalCell";
import AgentModal from "./agentModal";
import CustomerModal from "./customerModal";
import BeneficiaryModal from "./beneficiaryModal";
import { useMakeRequest } from "../../../../hooks/api";
import {
  formatPercentage,
  formatPhoneNumberObject,
  formatAgencyName,
  formatAgentStatus,
  concatFirstLastName,
  formatName,
  nameFormattingOptions,
  formatBeneficiaryType,
  formatBeneName,
  formatMoney,
  formatAddress,
  formatPhoneNumberString,
} from "../../../../utils/format";
import {
  userTypes,
  getAllWritingAgent,
  getAllServicingAgent,
  getBest,
  getAttentionLineByRole,
  getAgentCommunicationDetailsByType,
  communicationTypes,
} from "../../../../utils/findData";

import {
  A21G,
  SEE_APPLICATION,
  SEE_THE_APPLICATION,
  SEE_A21G,
} from "../../../../constants/logic";
import { useModalState } from "../../../../contexts/modal";
import {
  compareArrayOfStrings,
  containsArrayOfStrings,
  compareStrings,
  compareArraysForMatch,
} from "../../../../utils/string";
import { formatDate, getAge } from "../../../../utils/dates";
import { getRoleName, roleNames } from "../../../../utils/translate";
import { isArray, isDefined } from "../../../../utils/evaluate";
import { getConfig, types } from "../../../../config";
import { sortByProvidedOrder, sortNumber } from "../../../../hooks/table";
import {
  groupAndRemoveBeneficiaryDuplicates,
  findMatchingBeneficiaries,
  isDisabilityPolicy,
  isPallmPolicy,
  isRetireEaseContract,
  isCoverpathPolicy,
  isAnnuityContract,
} from "../../../../utils/policies";
import { useIsFixedAnnuityContract } from "../../../../hooks/helper";
import { sortAlphabeticallyByKey } from "../../../../utils/array";

export const useCustomerModalProps = (props) => {
  const {
    customer: selectedCustomer = {},
    isDisabilityPolicyResult,
    agreementKey,
    agreementData,
    annuityRequest = {},
  } = props;
  const { memberGUID } = selectedCustomer;
  const annuityData = annuityRequest?.data || {};
  const { lineOfBusinessCode } = agreementData;

  const customerRequest = useMakeRequest({
    apiRequest: "fetchCustomerData",
    apiParams: { memberGUID },
    immediateRequest: true,
    dataSelector: "processCustomersData",
  });
  const { data: customerData } = customerRequest;
  const { genderCode } = customerData;

  const address = getBest(customerData.addresses);
  const attentionLine = getAttentionLineByRole({
    customerAgreements: address?.customerAgreements,
    roles: selectedCustomer?.arrayRoleType,
    agreementKey,
  });
  const addressWithAttention = { ...address, attentionLine };

  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(addressWithAttention);

  const emailAddress = getBest(customerData.emails)?.value || "-";
  const phoneNumberObject = getBest(customerData.phones);
  const { phoneNumber, formattedPhoneNumber } = formatPhoneNumberObject(
    phoneNumberObject,
    "-"
  );

  const isRetireEase = isRetireEaseContract(agreementData);

  const asOfDate = formatDate(agreementData?.asOfDate, "MM/DD/YY", "-");

  selectedCustomer.roles = isDisabilityPolicyResult
    ? sortRoles(
        selectedCustomer.roles,
        isDisabilityPolicyResult,
        selectedCustomer
      ).join(", ")
    : selectedCustomer.roles;

  const lowerCaseRoles = selectedCustomer?.roles.toLowerCase() || "";

  const showGender =
    lowerCaseRoles.includes("insured") || isAnnuityContract(lineOfBusinessCode);

  let lifeStatus = [];
  if (isRetireEase) {
    const lifeStatusArray = isArray(annuityData.lifeStatus)
      ? annuityData.lifeStatus
      : [];
    const currentLifeStatus = lifeStatusArray?.filter(
      (ls) => selectedCustomer.governmentId === atob(ls.id)
    );
    lifeStatus =
      currentLifeStatus.length > 0 ? currentLifeStatus[0].status : "Unknown";
  }

  const showPayeeSection = lowerCaseRoles.includes("payee") && isRetireEase;
  const payee = getPayeeFromId(annuityData, selectedCustomer.governmentId);

  return {
    customerData: {
      ...customerData,
      emailAddress,
      attnLine,
      addrFirstLine: addrFirstLine || "-",
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
      phoneNumber,
      formattedPhoneNumber,
      genderCode: genderCode || "-",
    },
    customerRequest,
    isDisabilityPolicyResult,
    isRetireEase,
    asOfDate,
    showGender,
    lifeStatus,
    showPayeeSection,
    paymentStatus: payee.paymentStatus,
    paymentMethod: payee.paymentMethod,
    benefitPercentage: formatPercentage({
      rate: payee.payoutPercent,
    }),
    benefitAmount: formatMoney(payee.payoutAmount, "-"),
  };
};

export const useCustomerModal = (props) => {
  const { IS_DISABILITY_POLICY, agreementKey, agreementData, annuityRequest } =
    props || {};
  const { openModal } = useModalState();

  const showModal = (customer) => () => {
    const name = formatName({
      nameObject: customer,
      format: nameFormattingOptions.firstNameDisplayedFirst,
      defaultReturn: "-",
    });
    const formattedCustomer = {
      ...customer,
      name,
      formattedDob: formatDate(customer.birthDate) || "-",
      age: getAge(customer.birthDate, "-"),
      ssn: isDefined(customer.governmentIdLast4)
        ? `***-**-${customer.governmentIdLast4}`
        : "-",
    };

    const modalProps = {
      Component: CustomerModal,
      componentProps: {
        customer: formattedCustomer,
        isDisabilityPolicyResult: IS_DISABILITY_POLICY,
        agreementKey,
        agreementData,
        annuityRequest,
      },
      useHook: useCustomerModalProps,
      title: name,
    };
    openModal(modalProps);
  };
  return { showModal };
};

export const useBeneficiaryModal = () => {
  const { openModal } = useModalState();

  const showModal = (beneficiary) => () => {
    const formattedBeneficiary = formatBeneficiary(beneficiary);
    const modalProps = {
      Component: BeneficiaryModal,
      componentProps: { beneficiary: formattedBeneficiary },
      title: formattedBeneficiary.beneficiaryName,
    };
    openModal(modalProps);
  };
  return { showModal };
};

export const formatBeneficiary = (beneficiary) => {
  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(beneficiary.address);

  const emailAddress = beneficiary.email || "-";
  const phoneNumberObject = beneficiary.phone;
  const { phoneNumber, formattedPhoneNumber } = formatPhoneNumberObject(
    phoneNumberObject,
    "-"
  );
  const formattedAsOfDate = formatDate(
    beneficiary.asOfDate,
    "monthCommaDayYear"
  );
  const formattedBeneficiary = {
    ...beneficiary,
    formattedName: formatBeneName({
      nameObject: beneficiary.nameObject,
      beneType: beneficiary.beneficiaryType,
    }),
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    emailAddress,
    phoneNumber,
    formattedPhoneNumber,
    asOfDate: formattedAsOfDate,
  };

  return formattedBeneficiary;
};

export const useAgentModalProps = (props) => {
  const {
    agentProps: { businessPartnerId },
  } = props;
  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducerData",
    apiParams: { producerId: businessPartnerId },
    immediateRequest: true,
    dataSelector: "processProducerData",
  });
  const { data: producerData } = producerRequest;
  const { address } = producerData;
  const agentProfileRequest = useMakeRequest({
    apiRequest: "fetchAgentProfile",
    apiParams: { businessPartnerId },
    immediateRequest: true,
    dataSelector: "processAgentProfileData",
  });
  const agentProfileData = agentProfileRequest?.data || {};

  const { communications = [] } = agentProfileData;
  const agentEmail = getAgentCommunicationDetailsByType(
    communications,
    communicationTypes.EMAIL
  );

  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(address);

  return {
    address: {
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    },
    producerRequest,
    agentProfileRequest,
    emailAddress: agentEmail || "-",
  };
};

export const useAgentModal = (agreementData) => {
  const { openModal } = useModalState();
  const IS_FIXED_ANNUITY = useIsFixedAnnuityContract(agreementData);
  const showModal = (agent) => () => {
    const formattedAgentName = concatFirstLastName(
      agent.firstName,
      agent.lastName,
      agent.fullName
    );

    const {
      businessPartnerId,
      phoneNumber,
      emailAddress,
      status,
      contractTypeCodeDesc,
      commissionSplit,
    } = agent;

    const agentProps = {
      formattedAgentName,
      businessPartnerId,
      phoneNumber: phoneNumber || "-",
      formattedPhoneNumber: formatPhoneNumberString(phoneNumber) || "-",
      emailAddress: emailAddress || "-",
      agencyName: formatAgencyName(agent),
      status: formatAgentStatus(status),
      commissionSplit,
      contractType:
        isDefined(contractTypeCodeDesc) && !IS_FIXED_ANNUITY
          ? contractTypeCodeDesc
          : "Unavailable",
    };
    const modalProps = {
      Component: AgentModal,
      componentProps: { agentProps },
      useHook: useAgentModalProps,
      title: formattedAgentName,
    };
    openModal(modalProps);
  };
  return { showModal };
};

export const removeDuplicates = (agents) =>
  agents.reduce((uniqueAgents, currentAgent) => {
    if (
      !uniqueAgents.some(
        (agent) => agent.businessPartnerId === currentAgent.businessPartnerId
      )
    ) {
      return uniqueAgents.concat(currentAgent);
    }
    return uniqueAgents;
  }, []);

export const getPeople = (
  notBeneficiaries,
  isCalledFromPeopleOnPolicyOrContract = false
) => {
  if (!isArray(notBeneficiaries)) {
    return [];
  }
  const condensedNonBenes = notBeneficiaries.reduce((people, customer) => {
    const { roleType, roleSubType } = customer;
    const roleName = getRoleName(roleType, roleSubType);

    let matchingPeople;
    if (isCalledFromPeopleOnPolicyOrContract) {
      matchingPeople = groupAndRemoveBeneficiaryDuplicates(customer, people);
    } else {
      matchingPeople = people.filter((person) =>
        compareStrings(customer.memberGUID, person.memberGUID)
      );
    }
    const PERSON_ALREADY_EXISTS = matchingPeople.length > 0;
    if (PERSON_ALREADY_EXISTS) {
      return people.map((person) => {
        let CORRECT_PERSON;
        if (isCalledFromPeopleOnPolicyOrContract) {
          const { MEM_ID_MATCH, FULL_NAME_MATCH, NAME_MATCH, GOV_ID_MATCH } =
            findMatchingBeneficiaries(person, customer);

          if (compareStrings(person.type, "I")) {
            CORRECT_PERSON = MEM_ID_MATCH && NAME_MATCH && GOV_ID_MATCH;
          } else if (compareStrings(person.type, "N")) {
            CORRECT_PERSON = MEM_ID_MATCH && FULL_NAME_MATCH && GOV_ID_MATCH;
          }
        } else {
          CORRECT_PERSON = compareStrings(
            customer.memberGUID,
            person.memberGUID
          );
        }
        if (CORRECT_PERSON) {
          return {
            ...person,
            owner: person.owner
              ? person.owner
              : compareArrayOfStrings(
                  [userTypes.FRAN, userTypes.OWNER],
                  roleType
                ),
            insured: person.insured
              ? person.insured
              : compareStrings(roleType, userTypes.INSURED),
            premiumPayor: person.premiumPayor
              ? person.premiumPayor
              : compareStrings(roleType, userTypes.PREMIUM_PAYER),
            assignee: person.assignee
              ? person.assignee
              : compareStrings(roleType, userTypes.ASSIGNEE),
            powerOfAttorney: person.powerOfAttorney
              ? person.powerOfAttorney
              : compareStrings(roleType, userTypes.POWER_OF_ATTORNEY),
            alternateAddressee: person.alternateAddressee
              ? person.alternateAddressee
              : compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
                compareStrings(roleSubType, "SECA"),
            recipientOfBenefits: person.recipientOfBenefits
              ? person.recipientOfBenefits
              : compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
                compareStrings(roleSubType, "BEN"),
            roles: person.roles ? [...person.roles, roleName] : [roleName],
            arrayRoleType: person.arrayRoleType.concat(customer.roleType),
            arrayRoleSubType: person.arrayRoleSubType.concat(
              customer.roleSubType
            ),
          };
        }
        return person;
      });
    }
    const newCustomer = {
      ...customer,
      customerName: formatName({
        nameObject: customer,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      }),
      owner: compareArrayOfStrings([userTypes.FRAN, userTypes.OWNER], roleType),
      insured: compareStrings(roleType, userTypes.INSURED),
      premiumPayor: compareStrings(roleType, userTypes.PREMIUM_PAYER),
      assignee: compareStrings(roleType, userTypes.ASSIGNEE),
      powerOfAttorney: compareStrings(roleType, userTypes.POWER_OF_ATTORNEY),
      alternateAddressee:
        compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
        compareStrings(roleSubType, "SECA"),
      recipientOfBenefits:
        compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
        compareStrings(roleSubType, "BEN"),
      roles: roleName ? [roleName] : null,
      arrayRoleType: [customer.roleType],
      arrayRoleSubType: [customer.roleSubType],
    };
    return people.concat(newCustomer);
  }, []);

  const condensedNonBeneRolesSorted = condensedNonBenes.map((nonBene) => ({
    ...nonBene,
    roles: nonBene.roles ? sortRoles(nonBene.roles).join(", ") : "-",
  }));

  return condensedNonBeneRolesSorted;
};

// Sorting by role then subrole
export const sortByRole = (props) => {
  const { list, searchRoles, primaryRole, seconardyRole } = props;
  if (
    !isArray(list) ||
    !isArray(searchRoles) ||
    !isArray(primaryRole) ||
    !isArray(seconardyRole)
  ) {
    return [];
  }
  const withRole = list.filter((person) =>
    compareArraysForMatch(person.arrayRoleType, searchRoles)
  );
  const withoutRole = list.filter(
    (person) => !compareArraysForMatch(person.arrayRoleType, searchRoles)
  );

  // get primary then seconadry role and then put these roles at the start,
  // if role is not found null is returned and then filtered with isDefined
  const organizedRoles = [
    getRole(withRole, primaryRole),
    getRole(withRole, seconardyRole),
  ].filter((item) => isDefined(item));
  return [...organizedRoles, ...withoutRole];
};

// finding the subrole item
export const getRole = (list, roles) => {
  if (!isArray(list) || !isArray(roles)) {
    return null;
  }
  return list.reduce((foundItem, currentItem) => {
    const ITEM_FOUND = isDefined(foundItem);
    const FOUND_SUB_ROLE = compareArraysForMatch(
      currentItem.arrayRoleSubType,
      roles
    );

    if (FOUND_SUB_ROLE && !ITEM_FOUND) {
      return currentItem;
    }
    return foundItem;
  }, null);
};

export const sortRoles = (
  roles,
  isDisabilityPolicyResult = false,
  customer = {}
) => {
  const sortedRoles = [];
  if (roles.includes(roleNames.OWNR)) {
    sortedRoles.push(roleNames.OWNR);
  }
  if (roles.includes(roleNames.INSD)) {
    sortedRoles.push(roleNames.INSD);
  }
  if (roles.includes(roleNames.PPAY)) {
    sortedRoles.push(roleNames.PPAY);
  }
  if (roles.includes(roleNames.ASGN)) {
    sortedRoles.push(roleNames.ASGN);
  }

  if (isDisabilityPolicyResult) {
    if (roles.includes(roleNames.RCP)) {
      if (customer.recipientOfBenefits) sortedRoles.push(roleNames.RCPBEN);
    }
    if (roles.includes(roleNames.RCPBEN)) {
      sortedRoles.push(roleNames.RCPBEN);
    }
    if (roles.includes(roleNames.POA)) {
      sortedRoles.push(roleNames.POA);
    }
  } else {
    if (roles.includes(roleNames.POA)) {
      sortedRoles.push(roleNames.POA);
    }
    if (roles.includes(roleNames.RCP)) {
      sortedRoles.push(roleNames.RCP);
    }
  }

  return sortedRoles;
};

export const usePeopleOnPolicyProps = (props) => {
  const { agreementRequest, producerData } = props;
  const agreementData = agreementRequest.data;
  const {
    agreementKey,
    agreementKeyAdmin,
    agreementCustomers,
    asOfDate,
    lineOfBusinessCode,
  } = agreementData;

  const isPolicyHap = isCoverpathPolicy(agreementKeyAdmin);

  const IS_DISABILITY_POLICY = isDisabilityPolicy({ lineOfBusinessCode });

  const isPallm = isPallmPolicy(agreementData);

  const beneficiaryRequest = useMakeRequest({
    apiRequest: "fetchBeneficiaries",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processBeneficiaryRequest",
  });

  useEffect(() => {
    if (!IS_DISABILITY_POLICY && !isPallm) {
      beneficiaryRequest.executeRequest();
    }
  }, [IS_DISABILITY_POLICY]);

  const notBeneficiaries = agreementCustomers.filter(
    (customer) => !compareStrings(customer.roleType, userTypes.BENEFICARY)
  );
  const peopleListUnsorted = getPeople(notBeneficiaries, true);

  const peopleList = sortPeopleByRoles(peopleListUnsorted);
  const writingAgents = removeDuplicates(
    getAllWritingAgent({
      agents: producerData.producers,
    })
  );
  const servicingAgents = removeDuplicates(
    getAllServicingAgent({
      agents: producerData.producers,
    })
  );

  let complexBeneficiaries = [];
  let showFootnote = false;
  const beneficiariesFormatted = beneficiaryRequest.data.beneficiaryArray
    ?.filter((beneficiary) => {
      const isComplexBeneficiary = checkForComplexBene(beneficiary);

      if (isComplexBeneficiary) {
        complexBeneficiaries = [beneficiary];
      }
      return !isComplexBeneficiary;
    })
    .map((beneficiary) => {
      const { amount, percent, beneficiaryClass } = beneficiary;
      let allocation = `-`;

      if (isDefined(amount)) {
        allocation = formatMoney(amount, "-");
      } else if (isDefined(percent)) {
        allocation = formatPercentage({
          rate: percent,
          divide: true,
          maxDecimalPlaces: 2,
        });
      }

      if (!isDefined(amount) && !isDefined(percent)) {
        showFootnote = true;
      }

      const formattedBeneName = formatBeneName({
        beneType: beneficiary.beneficiaryType,
        nameObject: beneficiary.nameObject,
        isHapPolicy: isPolicyHap,
      });

      let translatedBeneficiaryClass;
      if (compareStrings(beneficiaryClass.toLowerCase(), "prmr")) {
        translatedBeneficiaryClass = "Primary";
      } else if (compareStrings(beneficiaryClass.toLowerCase(), "scnd")) {
        translatedBeneficiaryClass = "Secondary";
      } else {
        translatedBeneficiaryClass = beneficiaryClass;
      }

      return {
        ...beneficiary,
        beneficiaryName: formattedBeneName,
        beneficiaryType: formatBeneficiaryType(beneficiary.beneficiaryType),
        beneficiaryClass: translatedBeneficiaryClass,
        allocation,
        asOfDate,
        showFootnoteModal: !isDefined(amount) && !isDefined(percent),
      };
    });

  const beneficiariesSortByBeneficiaryClass =
    sortBeneficiariesByAllocationThenClass(beneficiariesFormatted);

  const { showModal: showAgentModal } = useAgentModal();
  const { showModal: showCustomerModal } = useCustomerModal({
    IS_DISABILITY_POLICY,
    agreementKey,
    agreementData,
  });
  const { showModal: showBeneficiaryModal } = useBeneficiaryModal();

  const peopleOnPolicyColumns = addOptionalPeopleOnPolicyColumns(
    defaultPeopleOnPolicyColumns,
    peopleList,
    IS_DISABILITY_POLICY
  );

  return {
    requests: {
      beneficiaryRequest,
      producerData,
    },
    data: {
      peopleList,
      peopleOnPolicyColumns,
      agreementCustomers,
      agreementKey,
      columnsBeneficiary,
      beneficiariesSortByBeneficiaryClass,
      complexBeneficiaries,
      showFootnote,
      isPolicyHap,
      IS_DISABILITY_POLICY,
      isPallm,
      agentTable: formatAgentTable({ servicingAgents, writingAgents }),
    },
    functions: {
      showAgentModal,
      showCustomerModal,
      showBeneficiaryModal,
    },
  };
};

export const formatAgentTable = ({ servicingAgents, writingAgents }) => {
  const columns = [
    {
      label: "Agent Types",
      accessor: "rowTitle",
      alignment: "left",
    },
    {
      accessor: "agentName",
      label: "Agent Name",
      alignment: "left",
      CustomCell: PersonModalCell,
    },
    {
      accessor: "producerId",
      label: "Producer ID",
      alignment: "left",
    },
    {
      accessor: "commissionSplit",
      label: "Commission Split",
      alignment: "left",
    },
  ];

  const formattedServicingAgents = formatAgentsForTable({
    agents: servicingAgents,
    rowTitle: "Servicing Agent(s)",
  });

  const formattedWritingAgents = formatAgentsForTable({
    agents: writingAgents,
    rowTitle: "Writing Agent(s)",
  });

  const rows = [...formattedServicingAgents, ...formattedWritingAgents];

  return { columns, rows };
};

const formatAgentsForTable = (props) => {
  const { agents, rowTitle } = props;
  const sortedAgents = sortAlphabeticallyByKey(agents, "lastName");

  return sortedAgents.map((agent, index) => {
    const formattedAgentName = concatFirstLastName(
      agent.firstName,
      agent.lastName,
      agent.fullName
    );

    return {
      ...agent,
      rowTitle: index === 0 ? rowTitle : "",
      agentName: formattedAgentName,
      producerId: agent.businessPartnerId,
    };
  });
};

const defaultPeopleOnPolicyColumns = [
  {
    accessor: "customerName",
    label: "Name",
    alignment: "left",
    CustomCell: PersonModalCell,
  },
  {
    accessor: "owner",
    label: "Owner",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
  {
    accessor: "insured",
    label: "Insured",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
  {
    accessor: "premiumPayor",
    label: "Premium Payor",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
];

const columnsBeneficiary = [
  {
    accessor: "beneficiaryName",
    label: "Name",
    alignment: "left",
    CustomCell: PersonModalCell,
  },
  {
    accessor: "beneficiaryType",
    label: "Type",
    alignment: "left",
  },
  {
    accessor: "beneficiaryClass",
    label: "Class",
    alignment: "left",
  },
  {
    accessor: "allocation",
    label: "Distribution",
    alignment: "left",
  },
  {
    accessor: "relationship",
    label: "Relationship to Insured",
    alignment: "left",
  },
];

export const getCoverPathUrl = ({ policyId }) => {
  const coverpathUrl = getConfig(types.coverpathUrl);
  return `${coverpathUrl}/client-details?policyNumber=${btoa(policyId)}`;
};

export const checkForComplexBene = (beneficiary) =>
  (beneficiary?.beneficiaryType === "-" &&
    beneficiary?.beneficiaryDesignationDescription !== "") ||
  (beneficiary?.beneficiaryType?.toString().toLowerCase() ===
    "complex-free-form" &&
    beneficiary?.beneficiaryDesignationDescription !== "") ||
  (beneficiary?.beneficiaryDesignationDescription !== "" &&
    beneficiary?.beneficiaryDesignationDescription
      ?.toLowerCase()
      .includes("complex"));

export const checkComplexBeneArrangement = (beneficiaries) => {
  const complexDescriptionPhrases = [
    A21G,
    SEE_APPLICATION,
    SEE_THE_APPLICATION,
    SEE_A21G,
  ];
  return beneficiaries.some((beneficiary) => {
    const description = beneficiary?.beneficiaryDesignationDescription;
    return containsArrayOfStrings(complexDescriptionPhrases, description);
  });
};

export const sortBeneficiariesByAllocationThenClass = (beneficiaries) => {
  const beneficiariesSortByAllocation = sortNumber({
    columnName: "allocation",
    rows: beneficiaries,
  })?.reverse();

  const sortOrder = [
    "Primary",
    "Secondary",
    "Contingent",
    "Tertiary",
    "Quarternary",
    "Quinary",
    "Senary",
    "Septenary",
    "Octonary",
    "Nonary",
    "Denary",
  ];
  const beneficiariesSortByBeneficiaryClass = sortByProvidedOrder({
    columnName: "beneficiaryClass",
    rows: beneficiariesSortByAllocation,
    order: sortOrder,
  });

  return beneficiariesSortByBeneficiaryClass;
};

export const addOptionalPeopleOnPolicyColumns = (
  defaultColumns,
  peopleList,
  isDisabilityPolicyResult
) => {
  const peopleOnPolicyWithOptionalColumns = [...defaultColumns];

  const columnTracker = {
    isAssignee: false,
    isRecipientOfBenefits: false,
    isPowerOfAttorney: false,
    isAlternateAddressee: false,
  };

  peopleList.forEach((person) => {
    if (person.assignee) {
      columnTracker.isAssignee = true;
    }
    if (person.recipientOfBenefits) {
      columnTracker.isRecipientOfBenefits = true;
    }
    if (person.powerOfAttorney) {
      columnTracker.isPowerOfAttorney = true;
    }
    if (person.alternateAddressee) {
      columnTracker.isAlternateAddressee = true;
    }
  });

  if (columnTracker.isAssignee) {
    peopleOnPolicyWithOptionalColumns.push(makeColumn("assignee", "Assignee"));
  }

  if (isDisabilityPolicyResult) {
    if (columnTracker.isRecipientOfBenefits) {
      peopleOnPolicyWithOptionalColumns.push(
        makeColumn("recipientOfBenefits", "Recipient of Benefits")
      );
    }
  }

  if (columnTracker.isPowerOfAttorney) {
    peopleOnPolicyWithOptionalColumns.push(
      makeColumn("powerOfAttorney", "Power of Attorney")
    );
  }

  if (!isDisabilityPolicyResult) {
    if (columnTracker.isAlternateAddressee) {
      peopleOnPolicyWithOptionalColumns.push(
        makeColumn("alternateAddressee", "Alternate Addressee")
      );
    }
  }

  return peopleOnPolicyWithOptionalColumns;
};

const makeColumn = (accessor, label) => ({
  accessor,
  label,
  alignment: "center",
  CustomCell: Icon,
  iconName: "check",
  iconColor: "green",
});

export const sortPeopleByRoles = (peopleList) => {
  const sortOrder = [
    "owner",
    "insured",
    "premiumPayor",
    "assignee",
    "powerOfAttorney",
    "alternateAddressee",
    "recipientOfBenefits",
  ];
  const copyOfPeopleList = cloneDeep(peopleList);
  const sortedPeopleList = [];

  sortOrder.forEach((role) => {
    let i = 0;
    while (i < copyOfPeopleList.length) {
      const person = copyOfPeopleList[i];
      if (person[role]) {
        sortedPeopleList.push(person);
        copyOfPeopleList.splice(i, 1);
      } else {
        i += 1;
      }
    }
  });

  return sortedPeopleList;
};

export const getPayeeFromId = (annuityData, customerId) => {
  const payeeArray = isArray(annuityData?.payee) ? annuityData?.payee : [];
  const filteredPayee = payeeArray.filter((payee) => {
    const id = atob(payee.id);
    return compareStrings(id, customerId);
  });
  return filteredPayee[0] || {};
};
