export const processAgentNotificationsData = (props) => {
  const notificationsData = props?.response?.data || {};

  const { notifications, currentDateTime } = notificationsData;

  return {
    notifications,
    currentDateTime,
  };
};
