/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { setTitle } from "../../utils/dom";
import EnvisionPolicyDetails from "./types/envision";
import AnnuityDetails from "./types/annuities";
import DisabilityPolicyDetails from "./types/disability";
import LifePolicyDetails from "./types/life";
import { usePolicyDetailProps } from "./hooks";
import "../../Print.scss";
import FiveHundredOneError from "../common/errors/501";
import FourHundredFourError from "../common/errors/404";
import ErrorPage from "../common/errors/errorPage";
import FiveHundredThreeError from "../common/errors/503";

const PolicyDetails = () => {
  setTitle("ServiceNet - Policy Details");
  const {
    pageToRender,
    apiRequests,
    unsupportedPolicy,
    contactUsNumber,
    contactUsTime,
    se2RedirectMessage,
  } = usePolicyDetailProps();

  switch (pageToRender) {
    case "life":
      return (
        <LifePolicyDetails
          apiRequests={apiRequests}
          unsupportedPolicy={unsupportedPolicy}
        />
      );
    case "envision":
      return <EnvisionPolicyDetails apiRequests={apiRequests} />;
    case "fixedAnnuitites":
      return (
        <AnnuityDetails
          apiRequests={apiRequests}
          unsupportedPolicy={unsupportedPolicy}
        />
      );
    case "disability":
      return <DisabilityPolicyDetails apiRequests={apiRequests} />;
    case "404":
      return (
        <FourHundredFourError
          transactionId={apiRequests?.agreementRequest?.transactionId}
        />
      );
    case "mmsdErrorPage":
      return (
        <ErrorPage
          errorTitle="Our records indicate you do not have access to this application."
          agreementKey={apiRequests?.agreementRequest?.agreementKey}
          errorPageFor="mmsdOnly"
          imageClass="illustration-error_500"
        />
      );
    case "mmsdSe2ErrorPage":
      return (
        <ErrorPage
          errorTitle={se2RedirectMessage}
          agreementKey={apiRequests?.agreementRequest?.agreementKey}
          errorPageFor="mmsdOnlySe2Error"
          imageClass="illustration-error_500"
        />
      );
    case "501":
      return <FiveHundredOneError />;
    case "restrictedContactsErrorPage": {
      const subTitle = `Please contact the Service Center at <a href="tel:+${contactUsNumber}">${contactUsNumber}</a>, ${contactUsTime}
      for more information about this contract.`;
      return (
        <ErrorPage
          errorTitle="This Contract is Currently Unavailable"
          errorSubtitle={subTitle}
          agreementKey={apiRequests?.agreementRequest?.agreementKey}
          errorPageFor="restrictedContactsErrorPage"
          imageClass="illustration-error_500"
        />
      );
    }
    case "503":
      return (
        <FiveHundredThreeError
          agreementData={apiRequests?.agreementRequest?.data}
          errorPageFor="unSupportedLob"
          transactionId={apiRequests?.agreementRequest?.transactionId}
        />
      );
    default:
      return (
        <FourHundredFourError
          transactionId={apiRequests.agreementRequest.transactionId}
        />
      );
  }
};

export default React.memo(PolicyDetails);
