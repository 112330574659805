import { useUserState } from "../../contexts/user";
import { useMakeRequest } from "../../hooks/api";
import { isDefined } from "../../utils/evaluate";
import { isHomeOfficeUser } from "../../utils/findData";
import { useLDValue } from "../common/featureFlags/hooks";

export const useAgentEmailNotificationsProps = () => {
  const numberOfDaysThreshold = useLDValue({
    flagName: ["maxDaysNewNotifications"],
  });

  const agentNotificationsRequest = useMakeRequest({
    apiRequest: "fetchAgentNotifications",
    apiParams: {
      daysThreshold: numberOfDaysThreshold,
      extended: true,
    },
    immediateRequest: true,
    dataSelector: "processAgentNotificationsData",
    initiateDevModeDispatch: true,
  });

  const notificationsDataHasLoaded = isDefined(
    agentNotificationsRequest?.data?.notifications ||
      agentNotificationsRequest?.data?.currentDateTime
  );

  let currentDateTime = "";
  if (notificationsDataHasLoaded) {
    currentDateTime = agentNotificationsRequest?.data?.currentDateTime;
  }

  const updateNotificationsLastViewedRequest = useMakeRequest({
    apiRequest: "updateNotificationsLastViewed",
    apiParams: {
      viewedAt: currentDateTime,
    },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const { user } = useUserState();

  const isHomeOfficeUserResult = isHomeOfficeUser(user);

  return {
    agentNotificationsRequest,
    isHomeOfficeUserResult,
    updateNotificationsLastViewedRequest,
    numberOfDaysThreshold,
  };
};
