import { useEffect } from "react";
import { getConfig, types } from "../config";
import { isDefined, isEmptyObject } from "../utils/evaluate";
import { hasKey } from "../utils/object";
import { useLDValue } from "../components/common/featureFlags/hooks";
import { isCoverpathPolicy } from "../utils/policies";

export const useVerifyPolicy = (props) => {
  const {
    setUnsupportedPolicy,
    ENVISION_POLICY,
    agreementRequest,
    cryptorEncryptionRequest,
    IS_HOME_OFFICE_USER,
    FIXED_ANNUITY_CONTRACT,
    isMmsdOnly,
    isSe2Product,
    isSigCareLtcProduct,
    isLtcRedirectOn,
  } = props;
  const {
    data: {
      policyId,
      lineOfBusinessCode,
      agreementKeyAdmin,
      majorProductCode,
      messages,
    },
  } = agreementRequest;

  const supportedProducts = useLDValue({
    flagName: ["supportedPolicies"],
  });

  const haveSupportedPoliciesLoaded = !isEmptyObject(supportedProducts);

  const POLICY_HAS_LOADED =
    isDefined(policyId) && isDefined(lineOfBusinessCode);

  const SUPPORTED_LOB =
    hasKey({ obj: supportedProducts, key: lineOfBusinessCode }) &&
    hasKey({
      obj: supportedProducts[lineOfBusinessCode],
      key: majorProductCode,
    }) &&
    supportedProducts[lineOfBusinessCode][majorProductCode].includes(
      agreementKeyAdmin
    );

  const redirectCodes = ["511", "512"];
  const IS_511_512 = redirectCodes.includes(messages?.code);

  const NOT_SUPPORTED_POLICY =
    (haveSupportedPoliciesLoaded &&
      POLICY_HAS_LOADED &&
      !SUPPORTED_LOB &&
      !ENVISION_POLICY &&
      !FIXED_ANNUITY_CONTRACT) ||
    IS_511_512;

  const isPolicyCoverPath = isCoverpathPolicy(agreementKeyAdmin);

  useEffect(() => {
    // Only for Coverpath redirection
    const coverpathUrl = getConfig(types.coverpathUrl);

    if (
      NOT_SUPPORTED_POLICY &&
      !IS_HOME_OFFICE_USER &&
      !isMmsdOnly &&
      isPolicyCoverPath
    ) {
      window.location.replace(
        `${coverpathUrl}/client-details?policyNumber=${btoa(policyId)}`
      );
    }

    setUnsupportedPolicy(NOT_SUPPORTED_POLICY);
  }, [
    policyId,
    NOT_SUPPORTED_POLICY,
    isPolicyCoverPath,
    IS_511_512,
    isSe2Product,
    IS_HOME_OFFICE_USER,
    isMmsdOnly,
  ]);

  const redirectToSE2 = NOT_SUPPORTED_POLICY && !isMmsdOnly && isSe2Product;
  const {
    policyId: encryptedPolicyId,
    suffix: encryptedSuffix,
    userIdNumber: encryptedUserIdNumber,
  } = cryptorEncryptionRequest?.data || {};
  const cryptorEncryptionError = cryptorEncryptionRequest?.error;

  useEffect(() => {
    // Only for SE2 redirection
    const se2Url = getConfig(types.se2Url);
    if (redirectToSE2) {
      cryptorEncryptionRequest?.executeRequest();
      if (encryptedPolicyId) {
        window.location.replace(
          `${se2Url}?cn=${encryptedPolicyId}&cs=${encryptedSuffix}&proxy=${encryptedUserIdNumber}`
        );
      }
      if (cryptorEncryptionError) {
        window.location.replace(se2Url);
      }
    }
  }, [
    encryptedPolicyId,
    redirectToSE2,
    cryptorEncryptionError,
    encryptedUserIdNumber,
  ]);

  const redirectToLtc =
    NOT_SUPPORTED_POLICY && isSigCareLtcProduct && isLtcRedirectOn;
  useEffect(() => {
    // Only for LTC redirection
    const sigCareLtcUrl = getConfig(types.sigCareLtcUrl);
    if (redirectToLtc) {
      window.location.replace(sigCareLtcUrl);
    }
  }, [redirectToLtc]);
};
