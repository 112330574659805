/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AGREEMENT_NOT_FOUND } from "../../constants/ui";
import { useUserState } from "../../contexts/user";
import { useMakeRequest } from "../../hooks/api";
import {
  getBpIdFromUser,
  getUsers,
  getUserBySubType,
  userTypes,
  getTrimmedPolicyIdFromAgreement,
  getPolicyIdFromAgreementKey,
} from "../../utils/findData";
import {
  isDisabilityPolicy,
  isSE2Policy,
  removeOwnerAndInsuredDuplicates,
  isAnnuityContract,
  isCoverpathPolicy,
  isSignatureCareLtcPolicy,
} from "../../utils/policies";
import { useIsFixedAnnuityContract } from "../../hooks/helper";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import { useIsFeatureOn, useLDValue } from "../common/featureFlags/hooks";
import { useVerifyPolicy } from "../../hooks/authorization";
import { typesOfAgents } from "../../data/typesOfAgents";
import { isDefined } from "../../utils/evaluate";
import { formatName, nameFormattingOptions } from "../../utils/format";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";
import { useAgentProfileState } from "../../contexts/agentProfile";
import { getConfig, types } from "../../config";

export const usePolicyDetailProps = () => {
  const { agreementKey } = useParams();
  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized: isUnauthorizedAgreement } = dependentAgreementData;
    const {
      type: agreementMessageType,
      description: agreementMessageDescription,
    } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorizedAgreement &&
      compareStrings(agreementMessageType, "SUCCESS") &&
      !compareStrings(agreementMessageDescription, AGREEMENT_NOT_FOUND)
    );
  };

  const loanRequest = useMakeRequest({
    apiRequest: "fetchLoanData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processLoanInformation",
    canExecuteCallBack: validRequest,
    initiateDevModeDispatch: false,
  });

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey, extendedApiCall: true },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
    initiateDevModeDispatch: false,
  });

  const serviceHistoryRequest = useMakeRequest({
    apiRequest: "fetchCatsEvents",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processServiceHistoryData",
    defaultReturn: [],
    canExecuteCallBack: validRequest,
    initiateDevModeDispatch: false,
  });

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
    updateRequestCallback: (dependentAgreementData) => {
      const { issueDate, jurisdictionState } = dependentAgreementData;
      const dataForSelectors =
        issueDate === ""
          ? { jurisdictionState }
          : { issueDate, jurisdictionState };
      return { paramsForSelectors: dataForSelectors };
    },
    canExecuteCallBack: validRequest,
    initiateDevModeDispatch: false,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [
      loanRequest,
      producersRequest,
      billingRequest,
      serviceHistoryRequest,
    ],
  });

  const agreementData = agreementRequest.data;

  const {
    messages: { description, code },
    isUnauthorized,
    kindCode,
    policyId,
    lineOfBusinessCode,
    restrictionCode,
    contactUsNumber,
    contactUsTime,
    agreementKeyAdmin,
    agreementKeyPrefix,
    agreementKeySuffix,
    minorProductCode,
  } = agreementData;

  const deathClaimEligibilityRequest = useMakeRequest({
    apiRequest: "fetchDeathClaimEligibilityData",
    apiParams: {
      primaryId: getPolicyIdFromAgreementKey(agreementKey),
      prefix: agreementKeyPrefix,
      suffix: agreementKeySuffix,
      adminSystemCode: agreementKeyAdmin,
    },
    immediateRequest: false,
    dataSelector: "processDeathClaimEligibilityData",
    defaultReturn: [],
  });

  const isPolicyDataLoaded = isDefined(agreementKeyAdmin);

  useEffect(() => {
    if (isPolicyDataLoaded) {
      deathClaimEligibilityRequest.executeRequest();
    }
  }, [isPolicyDataLoaded]);

  const mmsdLegacyFirms = useLDValue({
    flagName: ["se2LegacyFirms"],
  });

  /*
      Policy is Envision if LOB == "ANN" & kindCode == "ENVISN"
      Launch Darkly flag to enable/disable the Envision flow disableEnvisionFlow
  */
  const enableEnvisionFlow = useIsFeatureOn("enableEnvisionFlow");
  const ENVISION_POLICY =
    compareStrings("ENVISN", kindCode) &&
    compareStrings(lineOfBusinessCode, "ANN") &&
    enableEnvisionFlow;

  const FIXED_ANNUITY_CONTRACT = useIsFixedAnnuityContract({
    agreementKeyAdmin,
    lineOfBusinessCode,
    kindCode,
  });

  const IS_DISABILITY_POLICY = isDisabilityPolicy({ lineOfBusinessCode });

  const [unsupportedPolicy, setUnsupportedPolicy] = useState(true);

  const AGREEMENT_DOES_NOT_EXIST =
    compareStrings(description, "Agreement not found") &&
    compareStrings(code, "1");

  const { user } = useUserState();
  const { agentType } = user;
  const { idNumber: userIdNumber } = getBpIdFromUser(user);
  const IS_HOME_OFFICE_USER = compareArrayOfStrings(
    user.groups,
    "fim-servicenet-homeoffice"
  );

  const isMmsdOnly = compareStrings(agentType, typesOfAgents.MMSD);
  const isSe2Product = isSE2Policy({ agreementKeyAdmin });
  const isSigCareLtcProduct = isSignatureCareLtcPolicy({
    lineOfBusinessCode,
    minorProductCode,
  });
  const isLtcRedirectOn = useIsFeatureOn("redirectLtcsso");
  const isPolicyCoverpath = isCoverpathPolicy(agreementKeyAdmin);

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const se2PolicyId = trimmedPolicyId;

  const {
    agentProfile: {
      agentProfileData: { homeAgencyBusinessPartnerId },
    },
  } = useAgentProfileState();

  const cryptorEncryptionRequest = useMakeRequest({
    apiRequest: "fetchEncryptedDataUsingCryptor",
    apiParams: {
      policyId: `${se2PolicyId}`,
      suffix: agreementKeySuffix,
      userIdNumber,
    },
    immediateRequest: false,
    dataSelector: "processEncryptedDataUsingCryptor",
  });

  useVerifyPolicy({
    agreementKey,
    agreementRequest,
    cryptorEncryptionRequest,
    setUnsupportedPolicy,
    ENVISION_POLICY,
    FIXED_ANNUITY_CONTRACT,
    IS_HOME_OFFICE_USER,
    isMmsdOnly,
    isSe2Product,
    isSigCareLtcProduct,
    isLtcRedirectOn,
  });

  const hasPolicyLoaded = isDefined(policyId);

  const showLegacyMmsdLink = compareArrayOfStrings(
    mmsdLegacyFirms,
    homeAgencyBusinessPartnerId
  );

  let mmsdRedirectLink = "";
  let urlTitle = "";
  if (showLegacyMmsdLink) {
    mmsdRedirectLink = getConfig(types.mmSolutionsUrl);
    urlTitle = "MM Solutions";
  } else {
    mmsdRedirectLink = getConfig(types.unifiedWebUrl);
    urlTitle = "Unified Web";
  }
  const se2RedirectMessage = `Thank you for servicing this policy, please visit <a href=${mmsdRedirectLink}>${urlTitle}</a> for more details.`;

  const SHOW_MMSD_ERROR_PAGE =
    isMmsdOnly && hasPolicyLoaded && unsupportedPolicy;

  const SHOW_MMSD_SE2_ERROR_PAGE =
    isMmsdOnly && hasPolicyLoaded && isSe2Product;

  const displayUnsupportedLobErrorPageForHomeOffice =
    hasPolicyLoaded &&
    !isSe2Product &&
    !(isSigCareLtcProduct && isLtcRedirectOn) &&
    unsupportedPolicy &&
    IS_HOME_OFFICE_USER;

  const displayUnsupportedLobErrorPageForAdvisor =
    hasPolicyLoaded &&
    !isSe2Product &&
    !(isSigCareLtcProduct && isLtcRedirectOn) &&
    unsupportedPolicy &&
    !IS_HOME_OFFICE_USER &&
    !isPolicyCoverpath;

  const displayUnsupportedLobErrorPage =
    displayUnsupportedLobErrorPageForHomeOffice ||
    displayUnsupportedLobErrorPageForAdvisor ||
    (!hasPolicyLoaded && unsupportedPolicy); // When agreement api does not return the unsupported policy

  let pageToRender = "life";
  if (IS_DISABILITY_POLICY) pageToRender = "disability";
  if (ENVISION_POLICY) pageToRender = "envision";
  if (ENVISION_POLICY && !compareStrings(restrictionCode, "NR"))
    pageToRender = "restrictedContactsErrorPage";
  if (AGREEMENT_DOES_NOT_EXIST) pageToRender = "404";
  if (FIXED_ANNUITY_CONTRACT) pageToRender = "fixedAnnuitites";
  if (SHOW_MMSD_ERROR_PAGE && !SHOW_MMSD_SE2_ERROR_PAGE)
    pageToRender = "mmsdErrorPage";
  if (SHOW_MMSD_ERROR_PAGE && SHOW_MMSD_SE2_ERROR_PAGE)
    pageToRender = "mmsdSe2ErrorPage";
  if (displayUnsupportedLobErrorPage) pageToRender = "503";
  if (isUnauthorized) pageToRender = "501";

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Policy Details page",
  });

  return {
    pageToRender,
    apiRequests: {
      agreementRequest,
      billingRequest,
      loanRequest,
      producersRequest,
      serviceHistoryRequest,
      deathClaimEligibilityRequest,
    },
    unsupportedPolicy,
    contactUsNumber,
    contactUsTime,
    se2RedirectMessage,
  };
};

export const useBillingRequest = (props) => {
  const { agreementKey, agreementRequest } = props;
  const { issueDate, jurisdictionState } = agreementRequest.data;

  return useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey },
    dataSelector: "processBillingInformation",
    dataForSelectors: {
      issueDate,
      jurisdictionState,
    },
    immediateRequest: true,
    initiateDevModeDispatch: false,
  });
};

export const useProducersRequest = (props) => {
  const { agreementKey } = props;

  const { data, executeRequest } = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    initiateDevModeDispatch: false,
  });

  useEffect(() => {
    if (agreementKey) {
      executeRequest();
    }
  }, [agreementKey]);

  return data;
};

export const checkStatusAndReason = (props) => {
  const { status, statusReason } = props;
  if (!compareStrings(status, "IF")) {
    return false;
  }
  if (compareStrings(statusReason, "DP")) {
    return false;
  }
  return true;
};

export const checkSpecialConditions = (props) => {
  const {
    agreementKeyAdmin,
    agreementCoverages,
    specialConditionCode,
    specialCondition2Code,
  } = props;
  const HAS_LTCIR =
    agreementCoverages.filter((item) => compareStrings(item.type, "LTCIR"))
      .length > 0;
  const NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION =
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    HAS_LTCIR &&
    (compareStrings(specialCondition2Code, "LTCCL") ||
      compareStrings(specialConditionCode, "LTCCL"));
  return !NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION;
};

export const getTableRatingFromCustomers = (props) => {
  const { agreementCustomers } = props;
  const insuredCustomers =
    removeOwnerAndInsuredDuplicates(getUsers(agreementCustomers, "INSD")) || [];
  const selectedInsured = insuredCustomers.filter(
    (insured) => insured.roleSubType === "PRMR"
  );
  return selectedInsured.length > 0 &&
    selectedInsured[0].permanentTableRatingCode1
    ? selectedInsured[0].permanentTableRatingCode1
    : "None";
};

export const mecIndicatorText = `"This policy is a Modified Endowment Contract (MEC). As a MEC, distributions are taxable to the extent of any gain in the policy and are subject to a 10% tax penalty if the owner is younger than age 59 ½, if the owner is a corporation, or, in some cases, if the owner is a trust. We are required to report any taxable income resulting from any distribution to the Internal Revenue Service on a form 1099-R. A taxable gain quote can be calculated upon your request."`;

export const getJointOwnerInfo = (props) => {
  const defaultReturn = {
    jointOwnerNameLabel: "Joint Owner Name",
    displayJointOwner: false,
    jointOwnerName: "",
  };
  const { agreementCustomers, lineOfBusinessCode } = props;
  defaultReturn.jointOwner = getUserBySubType({
    type: userTypes.OWNER,
    subType: "JNT",
    people: agreementCustomers,
  });
  defaultReturn.jointOwnerName = isDefined(defaultReturn.jointOwner)
    ? formatName({
        nameObject: defaultReturn.jointOwner,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      })
    : "";
  defaultReturn.displayJointOwner =
    isAnnuityContract(lineOfBusinessCode) &&
    !compareStrings(defaultReturn.jointOwner.fullName, "");

  return defaultReturn;
};
